@import '../../portal.scss';

header {
    background-color: color(brand);
    color: color(primary);
}

.text-dark {
    text-transform: uppercase;
    color: color(primary) !important;
    font-weight: 300;
}

a.text-dark:hover, a.text-dark:focus {
    color: color(secondary) !important;
}

.navbar-brand {
    display: flex;
    align-items: center;
    padding: 0px;
}

.navbar-brand > img {
    padding: 0px 8px;
    width: 64px;
    height: 50px;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: color(primary);
    text-transform: uppercase;
}

html {
    font-size: fontSize(primary);
}

@media (min-width: 768px) {
    html {
        font-size: fontSize(secondary);
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
