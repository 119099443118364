@import '../../portal.scss';

.contact-us {
    font-size: fontSize(primary);

    .card {
        color: color(secondary) !important;
        margin: 20px 4px;
        height: 100%;
        min-width: 200px;
        min-height:200px;

        .card-title {
            margin: 8px 4px;
            padding: 8px 4px;
            font-size: fontSize(sub-header);
            font-weight: 400;
            text-transform: uppercase;
            background-color: color(secondary);
            color: color(primary) !important;
        }

        .card-body {
            font-weight: 300;

            p {
                padding: 8px 8px;
            }

            span {
                font-size: fontSize(secondary);
                font-weight: 300;
            }

            label {
                margin: 4px;
                font-size: fontSize(primary);
                text-transform: uppercase;
                padding: 0px 4px;
                font-weight: 400;
            }
        }
    }
}
