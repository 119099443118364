@import '../../portal.scss';

.social {
    font-size: fontSize(primary);

    img {
        vertical-align: bottom;
        width: 28px;
    }

    iframe {
        vertical-align: text-bottom;
        margin-right: 8px;

        .btn {
            background-color: color(secondary) !important;
        }
    }
}
