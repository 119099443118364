@import '../../portal.scss';

.banner {
    margin: 16px 0px;
    height: auto;
    border-radius: 4px;

    .caption {
        color: color(brand);
    }

    .carousel {
        background-color: color(secondary);
        border-radius: 4px;
        height: auto;

        li {
            background-color: color(brand);
        }

        img {
            border-radius: 4px;
            width: 100%;
            height: auto;
        }

        span.carousel-control-prev-icon {
            background-color: color(secondary) !important;
            border-radius: 49%;
            opacity: 0.60;
        }

        span.carousel-control-next-icon {
            background-color: color(secondary) !important;
            border-radius: 49%;
            opacity: 0.70;
        }
    }
}
