@import 'portal.scss';

body {
    margin: 0px;
    padding: 0px;
    background-color: color(secondary);
    color: color(primary);

    hr {
        border-top: 0.50px solid color(primary);
    }

    .btn-primary {
        color: color(secondary);
        background-color: color(primary);
        border-color: color(primary);
    }

    .main-section {
        font-size: fontSize(primary);
        background-color: color(secondary);
        color: color(primary);
        margin-bottom: 100px;

        label {
            margin: 8px 0px;
            font-size: fontSize(header);
            text-transform: uppercase;
        }

        section {
            border-top: 0.50px solid color(primary);
        }
    }
    .col {
        margin: 10px auto;
    }
}
