@import '../../portal.scss';

.footer-ui {
    background-color: color(brand);
    border-top: 0.50px solid color(primary);
    border-bottom: 0.50px solid color(primary);
    font-size: fontSize(primary);
    padding: 12px 0px;
    font-weight: 300;
    height: 50px;
    width:100%;

    section {
        margin: 2px 10px;
        float: right;

        p {
            background-color: color(brand);
            padding: 0px;
            margin: 0px;

            span {
                border-left: 0.50px solid color(primary);
                padding-left: 8px;
            }
        }
    }
}
