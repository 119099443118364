@import '../../portal.scss';

.career {
    font-size: fontSize(primary);
    height: 100%;
}

.upload-section {
    padding: 20px 4px;
    button {
        text-transform: uppercase;
    }
}
