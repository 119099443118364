@import '../../portal.scss';

.service {
    font-size: fontSize(primary);
    min-height: 150px;
    .col {
        margin: 4px 0px;
        min-width: 200px;
    }
}
