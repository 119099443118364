@import '../../portal.scss';

.card-ui {
    font-size: fontSize(primary);
    height: 100%;

    label {
        padding: 4px 2px;
        width: 100%;
        font-weight: 400;
        font-size: fontSize(sub-header) !important;
        border-bottom: 0.50px solid color(primary);
        color: color(secondary) !important;
    }

    .card {
        color: color(secondary) !important;
        margin: 24px 4px;
        height: 100%;
        min-width: 200px;

        .card-title {
            font-size: fontSize(sub-header);
            border-bottom: 0.50px solid color(secondary);
            font-weight: 400;
        }

        ul {
            padding: 8px 16px;
            font-weight: 300;
            font-size: fontSize(primary);
            color: color(secondary) !important;
        }
    }
}
